import React from 'react';
import Tooltip from 'cccisd-tooltip';
import Bin from 'cccisd-icons/bin';
import notification from 'cccisd-notification';
import Confirm from 'cccisd-confirm';
import RotateIcon from 'cccisd-icons/rotate-cw3';
import axios from 'cccisd-axios';

const Boilerplate = window.cccisd.boilerplate;

export default settings => {
    return ({ value, row, loadData }) => {
        const handleResend = async event => {
            //  STRUCTURE DATA

            const data = {
                coachId: row['requestor.pawn.pawnId'],
                pawnId: row['requesteePawn.pawn.pawnId'],
            };

            // CALL
            const response = await axios.put(
                Boilerplate.route('app.coach.resend.invite', {
                    pawnId: row['requesteePawn.pawn.pawnId'],
                }),
                data
            );

            if (response.data && response.data.status === 'success') {
                notification({
                    message: `The invititation for ${row['requesteePawn.user.username']} has been resent.`,
                    type: 'success',
                    duration: 5000,
                });
                loadData();
            } else {
                notification({
                    message: `${response.data.errors.error[0]}`,
                    type: 'danger',
                    duration: 5000,
                });
            }
        };

        const handleDelete = async () => {
            const data = {
                coachId: row['requestor.pawn.pawnId'],
                teacherId: row['requesteePawn.pawn.pawnId'],
            };
            const response = await axios.post(
                Boilerplate.route('delete.coach.teacher.invite'),
                data
            );
            if (response.data && response.data.status === 'success') {
                notification({
                    message: `The invitation for ${row['requesteePawn.user.username']} has been deleted.`,
                    type: 'success',
                    duration: 5000,
                });
                loadData();
            }
        };
        const confirmDelete = () => {
            Confirm({
                message: `Are you sure you want to delete the invitation to ${row['requesteePawn.user.username']}?`,
                description: `You are about to delete the invitation for user ${row['requesteePawn.user.username']}.`,
                confirmLabel: 'Unlink',
                abortLabel: 'Cancel',
            })
                .then(() => {
                    handleDelete();
                })
                .fail(() => {
                    // close modal
                });
        };

        const confirmResend = () => {
            Confirm({
                message: `Are you sure you want to resend the invitation to ${row['requesteePawn.user.username']}?`,
                description: `You are about to resend the invitation for user ${row['requesteePawn.user.username']}.`,
                confirmLabel: 'Resend',
                abortLabel: 'Cancel',
            })
                .then(() => {
                    handleResend();
                })
                .fail(() => {
                    // close modal
                });
        };

        return (
            <div key={row} className="text-center">
                <div
                    className="btn"
                    onClick={confirmResend}
                    style={{ backgroundColor: '#62AC4B', color: '#fff' }}
                >
                    <Tooltip title="Resend Invitation">
                        <RotateIcon />
                    </Tooltip>
                </div>{' '}
                <div
                    className="btn"
                    onClick={confirmDelete}
                    style={{ backgroundColor: '#D9534E', color: '#fff' }}
                >
                    <Tooltip title="Delete Invitation">
                        <Bin />
                    </Tooltip>
                </div>
            </div>
        );
    };
};
