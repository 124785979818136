import React from 'react';
import PropTypes from 'prop-types';
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import { Nav as SecondaryNav } from 'cccisd-laravel-appdefs';

var AppDefs = window.cccisd.appDefs;
var Fortress = window.cccisd.fortress;
import { Footer } from 'cccisd-laravel-boilerplate';
import style from './style.css';

export default class FixedLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
    };

    render() {
        return (
            <div className={style.body}>
                <div className={style.content}>
                    {Fortress.auth() && (
                        <SecondaryNav className="container" navs={AppDefs.navs} routes={AppDefs.routes} />
                    )}
                    <div className="container">{this.props.children}</div>
                </div>
                <div className={style.footer}>
                    <Footer className="container" />
                </div>
            </div>
        );
    }
}
