import React, { useEffect, useState } from 'react';
import { client } from 'cccisd-apollo';
import { SecureMessagingMultipleConversations } from 'cccisd-laravel-secure-messaging';
import query from './teachers.graphql';
import style from './style.css';

function SecureMessagingCoach() {
    const [anotherParticipants, setAnotherParticipants] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchTeachers() {
            const response = await client.query({
                query,
                variables: {},
                fetchPolicy: 'network-only',
            });

            const teacherList = response.data.roles.teacherList;
            const newAnotherParticipants = teacherList.map(teacher => ({
                id: teacher.pawn.pawnId,
                firstName: teacher.user.firstName,
                lastName: teacher.user.lastName,
                username: teacher.user.username,
            }));

            setAnotherParticipants(newAnotherParticipants);
            setLoading(false);
        }

        fetchTeachers();
    }, []);

    if (loading) {
        return null;
    }

    const user = window.cccisd.fortress.user;
    const primaryParticipant = {
        id: user.pawnId,
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username,
    };

    return (
        <div className={style.messageBox}>
            <SecureMessagingMultipleConversations
                primaryParticipant={primaryParticipant}
                anotherParticipants={anotherParticipants}
            />
        </div>
    );
}

export default SecureMessagingCoach;
