import React from 'react';
import Modal from 'cccisd-modal';
import PlusCircle from 'cccisd-icons/plus-circle2';
import axios from 'cccisd-axios';
import InviteForm from './form.js';
import PropTypes from 'prop-types';
import notification from 'cccisd-notification';
const Fortress = window.cccisd.fortress;
const Boilerplate = window.cccisd.boilerplate;
import style from './style.css';
export default class InviteButton extends React.Component {
    static propTypes = {
        loadData: PropTypes.func,
        afterSubmit: PropTypes.func,
    };

    // modal = React.createRef();

    handleSubmit = vals => {
        const userRole = Fortress.user.acting.data_type;
        const isAdmin = userRole === 'admin';
        const data = {
            ...vals,
            username: vals.email,
            // Will be 'requestingPawn'
            coachId: isAdmin ? null : Fortress.user.acting.id,
            adminId: isAdmin ? Fortress.user.acting.id : null,
            invite: true,
            role: isAdmin ? vals.role : 'teacher',
        };

        this.sendData(data);
        this.modal.close();
    };

    sendData = async data => {
        const result = await axios.post(Boilerplate.route('api.nexus.pawn.store'), data);

        if (result.data && result.data.status === 'success') {
            notification({
                message: `${data.username} has been invited.`,
                type: 'success',
                duration: 5000,
            });
            this.props.afterSubmit();
        } else {
            if (
                result.data.errors.username[0] ===
                'The specified username belongs to an active user.'
            ) {
                // is teacher linked to this coach? => message
                // if linked to another or not linked => pending and send invite
                const userRole = Fortress.user.acting.data_type;
                const isAdmin = userRole === 'admin';
                const route = isAdmin
                    ? 'admin.invite.existing.user'
                    : 'coach.invite.existing.teacher';
                const response = await axios.post(Boilerplate.route(route), data);
                if (response.data && response.data.status === 'success') {
                    notification({
                        message: `${data.username} has been invited.`,
                        type: 'success',
                        duration: 5000,
                    });
                    this.props.afterSubmit();
                } else {
                    notification({
                        message: `${response.data.errors.error[0]}`,
                        type: 'danger',
                        duration: 5000,
                    });
                }
            } else if (result.data.errors.username) {
                notification({
                    message: `${result.data.errors.username[0]}`,
                    type: 'danger',
                    duration: 5000,
                });
            } else {
                notification({
                    message: `An error occured trying to invite a teacher`,
                    type: 'danger',
                    duration: 5000,
                });
            }
            this.props.afterSubmit();
        }
    };

    render() {
        const userRole = Fortress.user.acting.data_type;
        return (
            <div style={{ width: '100%' }}>
                <Modal
                    ref={elem => {
                        this.modal = elem;
                    }}
                    trigger={
                        <div className={`btn btn-primary ${style.inviteButton}`}>
                            <PlusCircle />{' '}
                            {userRole === 'admin' ? 'Invite Team Member' : 'Invite Teacher'}
                        </div>
                    }
                    size="medium"
                    title={userRole === 'admin' ? 'Invite Team Member' : 'Invite Teacher'}
                >
                    <InviteForm handleSubmit={this.handleSubmit} />
                </Modal>
            </div>
        );
    }
}
