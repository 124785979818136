import React, { useState } from 'react';
import _get from 'lodash/get';
import { client as apollo } from 'cccisd-apollo';
import Modal from 'cccisd-modal';
import pawnMessagesQuery from './pawnMessagesQuery.graphql';
import { RenderLoggedMessage } from 'cccisd-laravel-nexus';

const LoggedPawnMessages = ({ deploymentIds, row }) => {
    const [messages, setMessages] = useState([]);

    const messageCount = row.messageCount;
    const pawnId = row['pawn.pawnId'];

    const getMessages = async () => {
        if (messageCount > 0 && messageCount !== messages.length && deploymentIds) {
            let result = await apollo.query({
                query: pawnMessagesQuery,
                fetchPolicy: 'network-only',
                variables: {
                    deploymentIds: [7, 8],
                    pawnId,
                },
            });
            let resultMessages = _get(result, 'data.messages.messageList');
            setMessages(resultMessages);
        }
    };

    if (!messageCount) {
        return <div>{messageCount}</div>;
    }

    return (
        <Modal
            trigger={
                <a href="" onClick={getMessages}>
                    {messageCount}
                </a>
            }
            title="Sent Messages"
        >
            {messages.map((message, i) => {
                const separator = i < messages.length - 1 ? <hr /> : null;
                const content = <RenderLoggedMessage {...message} />;

                return (
                    <>
                        <div
                            key={message.messageId}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            {content}
                        </div>
                        {separator}{' '}
                    </>
                );
            })}
        </Modal>
    );
};

export default LoggedPawnMessages;
