// ***** COPIED TO FIX RESPONSIVE SIZING ISSUES *****
// ***** COPIED FRAMEWORK FROM WHIPPY ISSUES *****

import builder from './Builder';
import {
    icon,
    initialValues as builderInitialValues,
    getDataDefinitions,
} from './Builder/settings.js';
import player from './Player';
import notification from 'cccisd-notification';

export default {
    code: 'practice_report',
    name: 'Practice Report',
    labelField: 'Practice Report',
    icon,
    builder,
    builderInitialValues,
    player,
    getDataDefinitions,
    onBeforeNextPage: async ({ error }) => {
        if (error) {
            notification({ message: error, type: 'danger' });
            return Promise.reject();
        }
    },
};
