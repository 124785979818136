import React from 'react';
import PropTypes from 'prop-types';
import { playerWrapper } from 'cccisd-survey-common';
import { client } from 'cccisd-apollo';
import feedbackResponseQuery from './feedbackResponseQuery.graphql';
import Loader from 'cccisd-loader';
import IconWarn from 'cccisd-icons/warning';
import Triangle from 'cccisd-icons/triangle2';
import Square from 'cccisd-icons/square';
import Diamond from 'cccisd-icons/diamond4';
import Circle from 'cccisd-icons/circle2';

import style from './style.css';

const validate = (value, settings) => {
    let error;

    if (value > 0) {
        error = `There are ${value} required sections to be answered.`;
    }

    return error;
};

export class Player extends React.Component {
    static propTypes = {
        field: PropTypes.object,
        settings: PropTypes.object,
        isPreview: PropTypes.bool,
        pawnId: PropTypes.number,
        pawnHash: PropTypes.string,
        responseSet: PropTypes.number,
    };

    state = {
        loading: true,
    };

    componentDidMount() {
        const { isPreview } = this.props;

        if (isPreview) {
            return;
        }

        this._verifyAllResponses();
    }

    componentWillUpdate(newProps, newState) {}

    _getAllMetrics = async () => {
        const response = await client.query({
            query: feedbackResponseQuery,
            variables: {
                pawnId: this.props.pawnId,
                deploymentId: this.props.responseSet,
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        });

        const metrics = response.data.flows.assignmentProgress.devTagsLists;

        await this.setState({
            PhysicalLayoutItems: metrics.PhysicalLayoutItem || null,
            ClassroomRulesItems: metrics.ClassroomRulesItem || null,
            ClassroomRoutinesItems: metrics.ClassroomRoutinesItem || null,
            SmoothTransitionsItems: metrics.SmoothTransitionsItem || null,
            AcademicObjectivesItems: metrics.AcademicObjectivesItem || null,
            PacingInstructionItems: metrics.PacingInstructionItem || null,
            StudentAccuracyItems: metrics.StudentAccuracyItem ? metrics.StudentAccuracyItem : null,
            StudentEngagementItems: metrics.StudentEngagementItem || null,
            BehavioralExpectationsItems: metrics.BehavioralExpectationsItem || null,
            ActiveSupervisionItems: metrics.ActiveSupervisionItem || null,
            PraiseItems: metrics.PraiseItem || null,
            ReprimandsItems: metrics.ReprimandsItem || null,
            RatioItems: metrics.RatioItem || null,
            ReinforcementItems: metrics.ReinforcementItem || null,
            NoncontingentAttentionItems: metrics.NoncontingentAttentionItem || null,
            InteractionsItems: metrics.InteractionsItem || null,
            DisruptiveBehaviorItems: metrics.DisruptiveBehaviorItem || null,
            ScheduleItems: metrics.ScheduleItem || null,
        });
    };

    _verifyAllResponses = async () => {
        const { isPreview } = this.props;

        if (isPreview) {
            return;
        }

        await this._getAllMetrics();

        let csErrors = [];
        let imErrors = [];
        let bmErrors = [];
        let ccErrors = [];

        const PhysicalLayoutCount = 4;
        const ClassroomRulesCount = 4;
        const ClassroomRoutinesCount = 3;
        const SmoothTransitionsCount = 8;
        const AcademicObjectivesCount = 4;
        const PacingInstructionCount = 3;
        const StudentAccuracyCount = 3;
        const StudentEngagementCount = 3;
        const BehavioralExpectationsCount = 4;
        const ActiveSupervisionCount = 3;
        const PraiseCount = 4;
        const ReprimandsCount = 4;
        const RatioCount = 1;
        const ReinforcementCount = 4;
        const NoncontingentAttentionCount = 4;
        const InteractionsCount = 5;
        const DisruptiveBehaviorCount = 4;
        const ScheduleCount = 5;

        // check if all the required questions have been answered
        // remove empty string values

        const PhysicalLayout =
            this.state.PhysicalLayoutItems &&
            (await this.state.PhysicalLayoutItems.filter(item => {
                return item && item.trim() !== '';
            }));
        if (!PhysicalLayout || PhysicalLayout.length !== PhysicalLayoutCount) {
            csErrors.push('Physical Layout');
        }

        const ClassroomRules =
            this.state.ClassroomRulesItems &&
            (await this.state.ClassroomRulesItems.filter(item => {
                return item && item.trim() !== '';
            }));
        if (!ClassroomRules || ClassroomRules.length !== ClassroomRulesCount) {
            csErrors.push('Classroom Rules');
        }

        const ClassroomRoutines =
            this.state.ClassroomRoutinesItems &&
            (await this.state.ClassroomRoutinesItems.filter(item => {
                return item && item.trim() !== '';
            }));
        if (!ClassroomRoutines || ClassroomRoutines.length !== ClassroomRoutinesCount) {
            csErrors.push('Classroom Routines');
        }

        const SmoothTransitions =
            this.state.SmoothTransitionsItems &&
            (await this.state.SmoothTransitionsItems.filter(item => {
                return item && item.trim() !== '';
            }));
        if (!SmoothTransitions || SmoothTransitions.length !== SmoothTransitionsCount) {
            csErrors.push('Smooth Transitions');
        }

        const Schedule =
            this.state.ScheduleItems &&
            (await this.state.ScheduleItems.filter(item => {
                return item && item.trim() !== '';
            }));
        if (!Schedule || Schedule.length !== ScheduleCount) {
            imErrors.push('Schedule Posted and Followed');
        }

        const AcademicObjectives =
            this.state.AcademicObjectivesItems &&
            (await this.state.AcademicObjectivesItems.filter(item => {
                return item && item.trim() !== '';
            }));
        if (!AcademicObjectives || AcademicObjectives.length !== AcademicObjectivesCount) {
            imErrors.push('Academic Objectives');
        }

        const PacingInstruction =
            this.state.PacingInstructionItems &&
            (await this.state.PacingInstructionItems.filter(item => {
                return item && item.trim() !== '';
            }));
        if (!PacingInstruction || PacingInstruction.length !== PacingInstructionCount) {
            imErrors.push('Pacing of Instruction');
        }

        const StudentAccuracy =
            this.state.StudentAccuracyItems &&
            (await this.state.StudentAccuracyItems.filter(item => {
                return item && item.trim() !== '';
            }));
        if (!StudentAccuracy || StudentAccuracy.length !== StudentAccuracyCount) {
            imErrors.push('Student Accuracy');
        }

        const StudentEngagement =
            this.state.StudentEngagementItems &&
            (await this.state.StudentEngagementItems.filter(item => {
                return item && item.trim() !== '';
            }));
        if (!StudentEngagement || StudentEngagement.length !== StudentEngagementCount) {
            imErrors.push('Student Engagement');
        }

        const BehavioralExpectations =
            this.state.BehavioralExpectationsItems &&
            (await this.state.BehavioralExpectationsItems.filter(item => {
                return item && item.trim() !== '';
            }));
        if (
            !BehavioralExpectations ||
            BehavioralExpectations.length !== BehavioralExpectationsCount
        ) {
            bmErrors.push('Behavioral Expectations');
        }

        const ActiveSupervision =
            this.state.ActiveSupervisionItems &&
            (await this.state.ActiveSupervisionItems.filter(item => {
                return item && item.trim() !== '';
            }));
        if (!ActiveSupervision || ActiveSupervision.length !== ActiveSupervisionCount) {
            bmErrors.push('Active Supervision');
        }

        const Praise =
            this.state.PraiseItems &&
            (await this.state.PraiseItems.filter(item => {
                return item && item.trim() !== '';
            }));
        if (!Praise || Praise.length !== PraiseCount) {
            bmErrors.push('Use of Praise');
        }

        const Reprimands =
            this.state.ReprimandsItems &&
            (await this.state.ReprimandsItems.filter(item => {
                return item && item.trim() !== '';
            }));
        if (!Reprimands || Reprimands.length !== ReprimandsCount) {
            bmErrors.push('Use of Reprimands');
        }

        const Ratio =
            this.state.RatioItems &&
            (await this.state.RatioItems.filter(item => {
                return item && item.trim() !== '';
            }));
        if (!Ratio || Ratio.length !== RatioCount) {
            bmErrors.push('Positive to Negative Ratio');
        }

        const Reinforcement =
            this.state.ReinforcementItems &&
            (await this.state.ReinforcementItems.filter(item => {
                return item && item.trim() !== '';
            }));
        if (!Reinforcement || Reinforcement.length !== ReinforcementCount) {
            bmErrors.push('Use of Reinforcement');
        }

        const NoncontingentAttention =
            this.state.NoncontingentAttentionItems &&
            (await this.state.NoncontingentAttentionItems.filter(item => {
                return item && item.trim() !== '';
            }));
        if (
            !NoncontingentAttention ||
            NoncontingentAttention.length !== NoncontingentAttentionCount
        ) {
            ccErrors.push('Use of Noncontingement Attention');
        }

        const Interactions =
            this.state.InteractionsItems &&
            (await this.state.InteractionsItems.filter(item => {
                return item && item.trim() !== '';
            }));
        if (!Interactions || Interactions.length !== InteractionsCount) {
            ccErrors.push('Interactions with Students');
        }

        const DisruptiveBehavior =
            this.state.DisruptiveBehaviorItems &&
            (await this.state.DisruptiveBehaviorItems.filter(item => {
                return item && item.trim() !== '';
            }));
        if (!DisruptiveBehavior || DisruptiveBehavior.length !== DisruptiveBehaviorCount) {
            ccErrors.push('Level of Disruptive Behavior');
        }

        this.setState({ csErrors, imErrors, bmErrors, ccErrors, loading: false });

        this.props.field.onChange(
            csErrors.length + imErrors.length + bmErrors.length + ccErrors.length
        );
    };

    _renderErrors = () => {
        const { csErrors, imErrors, bmErrors, ccErrors } = this.state;

        return (
            <div>
                {csErrors.length > 0 && (
                    <div className={style.errorHeading}>
                        <h4>
                            <span className={style.errorTriangle}>
                                <Triangle /> Classroom Structure
                            </span>
                        </h4>

                        <ul>
                            {csErrors.map((error, index) => (
                                <li key={index} item={error}>
                                    {error}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {imErrors.length > 0 && (
                    <div className={style.errorHeading}>
                        <h4>
                            <span className={style.errorSquare}>
                                <Square /> Instruction Management
                            </span>
                        </h4>
                        <ul>
                            {imErrors.map((error, index) => (
                                <li key={index} item={error}>
                                    {error}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {bmErrors.length > 0 && (
                    <div className={style.errorHeading}>
                        <h4>
                            <span className={style.errorCircle}>
                                <Circle /> Behavior Management
                            </span>
                        </h4>
                        <ul>
                            {bmErrors.map((error, index) => (
                                <li key={index} item={error}>
                                    {error}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {ccErrors.length > 0 && (
                    <div className={style.errorHeading}>
                        <h4>
                            <span className={style.errorDiamond}>
                                <Diamond /> Classroom Climate
                            </span>
                        </h4>
                        <ul>
                            {ccErrors.map((error, index) => (
                                <li key={index} item={error}>
                                    {error}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        );
    };

    render() {
        const { isPreview } = this.props;
        const { csErrors, imErrors, bmErrors, ccErrors } = this.state;

        if (isPreview) {
            return (
                <div>
                    <h3>Validate Responses</h3>
                </div>
            );
        }

        if (this.state.loading) {
            return <Loader loading />;
        }

        return (
            <div>
                {csErrors.length > 0 ||
                imErrors.length > 0 ||
                bmErrors.length > 0 ||
                ccErrors.length > 0 ? (
                    <div>
                        <div className="text-danger">
                            <p className={style.warningMessage}>
                                <IconWarn /> You must answer all the questions before submitting
                                this survey. Please revisit the following sections via the side
                                navigation menu and answer the questions that were skipped.
                            </p>
                        </div>
                        {this._renderErrors()}
                    </div>
                ) : (
                    <p>
                        All questions have been answered. Please click the Next button to submit
                        your answers.
                    </p>
                )}
            </div>
        );
    }
}

export default playerWrapper({ validate })(Player);
