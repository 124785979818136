import React from 'react';
import IconApple from 'cccisd-icons/apple';

export const fields = ['text'];

export const initialValues = {
    text: '<h3>Validate Responses</h3>',
};

export const icon = <IconApple />;

export const getDataDefinitions = (id, settings) => {
    return {};
};

export const validate = values => {
    const errors = {};

    return errors;
};
