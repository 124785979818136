import React from 'react';
import PropTypes from 'prop-types';
// import DefaultHeader from '../default/DefaultHeader';
import style from './style.css';

export default class Component extends React.Component {
    static propTypes = {
        header: PropTypes.node,
        footer: PropTypes.node,
        showHeader: PropTypes.bool,
        showFooter: PropTypes.bool,
        children: PropTypes.node,
    };

    render() {
        const { showHeader, showFooter, footer } = this.props;
        const header = this.props.header;

        return (
            <div className={style.wrapper}>
                {showHeader && <div>{header}</div>}
                <div className={style.body}>
                    <div className="container">{this.props.children}</div>
                </div>
                {showFooter && footer && <div>{footer}</div>}
            </div>
        );
    }
}
