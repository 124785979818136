import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import Axios from 'cccisd-axios';
import ClickButton from 'cccisd-click-button';
import Confirm from 'cccisd-confirm';
import notification from 'cccisd-notification';
import IconArrowUp from 'cccisd-icons/arrow-up16';
import Tooltip from 'cccisd-tooltip';

var Boilerplate = window.cccisd && window.cccisd.boilerplate;

class PromoteRole extends Component {
    static propTypes = {
        row: PropTypes.object,
        settings: PropTypes.object,
        loadData: PropTypes.func,
    };

    Promote = async () => {
        const { row, loadData } = this.props;

        const pawnId = _get(row, 'pawn.pawnId');
        const pawnHash = _get(row, 'pawn.pawnHash');
        const fullName = _get(row, 'user.fullName');

        let data = {
            pawnId,
            pawnHash,
        };

        let response = await Axios.post(Boilerplate.route('promote.pawn.role'), data);
        if (response && response.data && response.data.status === 'success') {
            notification({
                message: `${fullName} is now an coach.`,
                type: 'success',
                duration: 3000,
            });

            loadData();
        } else {
            notification({
                message: `Error promoting role for ${fullName}.`,
                data: response && response.data && response.data.errors,
                duration: -1,
                type: 'danger',
            });
        }
    };

    confirm = () => {
        const { row } = this.props;

        const fullName = _get(row, 'user.fullName');

        Confirm({
            message: `Are you sure you want to promote ${fullName} to coach?`,
            description: `You are about to promote user ${fullName} from teacher to coach.`,
            confirmLabel: 'Promote Role',
            abortLabel: 'Cancel',
        })
            .then(() => {
                this.Promote();
            })
            .fail(() => {
                // close modal
            });
    };

    render() {
        return (
            <Tooltip title="Promote to Coach">
                <ClickButton
                    title={
                        <span>
                            <IconArrowUp />
                        </span>
                    }
                    className="btn btn-xs btn-warning"
                    onClick={this.confirm}
                />
            </Tooltip>
        );
    }
}

export default PromoteRole;
