import React from 'react';
import PropTypes from 'prop-types';
import { fields, validate, initialValues } from './settings.js';
import { builderWrapper, FieldBlock } from 'cccisd-survey-common';
import Editor from 'cccisd-wysiwyg';

export class Builder extends React.Component {
    static propTypes = {
        fields: PropTypes.object,
    };

    render() {
        const { text } = this.props.fields;

        return (
            <div>
                <FieldBlock field={text} label="Text:">
                    <Editor
                        content={text.value}
                        type="textarea-inline"
                        onChange={text.onChange}
                        selectAllOnFocus={text.value === initialValues.text}
                    />
                </FieldBlock>
            </div>
        );
    }
}

export default builderWrapper({
    fields,
    validate,
    initialValues,
    withTemplate: false,
    withAccommodations: false,
    withVisibilityConditions: true,
})(Builder);
