import React, { useState, useEffect } from 'react';
import CccisdToggle from 'cccisd-toggle';
import Axios from 'cccisd-axios';
import Notification from 'cccisd-notification';
import { client as apollo } from 'cccisd-apollo';
import deploymentQuery from './deploymentId.graphql';
import LoggedPawnMessages from './LoggedPawnMessages';
import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;
const MessageToggle = ({ value, row, loadData, settings }) => {
    const [toggleOn, setToggleOn] = useState(false);
    const [deploymentIds, setDeploymentIds] = useState(null);

    useEffect(() => {
        const toggleStatus = row[settings.path];
        if (toggleStatus) {
            setToggleOn(true);
        }

        async function getData() {
            const ids = await getDeploymentIds();
            setDeploymentIds(ids);
        }
        getData();
    }, [row[settings.path]]);

    const getDeploymentIds = async () => {
        const response = await apollo.query({
            query: deploymentQuery,
            fetchPolicy: 'network-only',
            variables: {
                deploymentHandleCoach: 'feedbackSurveyCoach',
                deploymentHandleTeacher: 'feedbackSurveyTeacher',
            },
        });

        let dIds = [];
        dIds.push(response.data.flows.coach?.deploymentId);
        dIds.push(response.data.flows.teacher?.deploymentId);

        return dIds;
    };

    const renderNumber = () => {
        return (
            <span style={{ marginRight: '1em' }}>
                <LoggedPawnMessages deploymentIds={deploymentIds} row={row} />
            </span>
        );
    };
    const handleChange = async () => {
        const data = {
            pawnHash: row['pawn.pawnHash'],
            automaticMessaging: !toggleOn,
        };

        const response = await Axios.put(
            Boilerplate.route('api.nexus.pawn.update', { pawn: row['pawn.pawnId'] }),
            data
        );

        if (response && response.data.status === 'success') {
            setToggleOn(!toggleOn);
        } else {
            Notification({
                message: 'Problem occured while updating messaging. Please contact your system administrator',
                type: 'danger',
            });
        }
    };

    return (
        <div className={style.toggleWrapper}>
            {renderNumber()}
            <CccisdToggle name="toggle" value={toggleOn} onChange={() => handleChange()} />
        </div>
    );
};

export default (settings, callbackHandler) => {
    return props => <MessageToggle {...props} settings={settings} />;
};
