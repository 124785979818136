import React, { useEffect, useState } from 'react';
import { client } from 'cccisd-apollo';
import {
    SecureMessagingSingleConversation,
    SecureMessagingMultipleConversations,
} from 'cccisd-laravel-secure-messaging';
import query from './MessagesWithCoach.graphql';
import style from './style.css';

const Fortress = window.cccisd.fortress;

function SecureMessagingTeacher() {
    const [anotherParticipant, setAnotherParticipant] = useState();
    const [coach, setCoach] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchCoach() {
            const response = await client.query({
                query,
                variables: {
                    pawnId: Fortress.user.acting.id,
                },
                fetchPolicy: 'network-only',
            });

            const anotherParticipants = [];
            const coachData = response.data.roles.teacher.parentRoles.coach;
            if (coachData) {
                const { pawn, user } = coachData;
                const newParticipant = {
                    id: pawn.pawnId,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    username: user.username,
                };
                anotherParticipants.push(newParticipant);
                setCoach(newParticipant);
            }

            // const allConversations = response.data.messages.secureConversationList;
            // if (Array.isArray(allConversations)) {
            //     allConversations.forEach(convo => {
            //         if (convo.messageCount === 0) {
            //             return;
            //         }

            //         const particpants = convo.participantList;
            //         particpants.forEach(participant => {
            //             const participantId = participant?.pawn?.pawnId;
            //             if (participantId === Fortress.user.acting.id) {
            //                 return;
            //             }

            //             if (coach?.pawn?.pawnId && participantId === coach?.pawn?.pawnId) {
            //                 return;
            //             }

            //             anotherParticipants.push({
            //                 id: participantId,
            //                 firstName: participant?.user?.firstName,
            //                 lastName: participant?.user?.lastName,
            //                 username: participant?.user?.username,
            //                 readOnly: true,
            //             });
            //         });
            //     });
            // }

            setAnotherParticipant(anotherParticipants);
            setLoading(false);
        }

        fetchCoach();
    }, []);

    if (loading) {
        return null;
    }

    const user = Fortress.user;
    const primaryParticipant = {
        id: user.pawnId,
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username,
    };

    if (!coach) {
        return (
            <div className="alert alert-warning">
                <p>
                    You do not have a current coach. Once you are linked to a coach, you will be able to message your
                    Coach here.
                </p>
            </div>
        );
    }

    return (
        <div className={style.messageBox}>
            {anotherParticipant.length <= 1 ? (
                <SecureMessagingSingleConversation
                    primaryParticipant={primaryParticipant}
                    anotherParticipant={anotherParticipant[0]}
                />
            ) : (
                <SecureMessagingMultipleConversations
                    primaryParticipant={primaryParticipant}
                    anotherParticipants={anotherParticipant}
                />
            )}
        </div>
    );
}

export default SecureMessagingTeacher;
