import React from 'react';
import IconExclamation from 'cccisd-icons/notification2';
import IconCheck from 'cccisd-icons/checkmark-circle';
import IconHourGlass from 'cccisd-icons/hour-glass2';
import Modal from 'cccisd-modal';
import Table from 'cccisd-graphql-table';
import query from './surveyQuery.graphql';
import style from './style.css';
import _isEmpty from 'lodash/isEmpty';
import format from 'date-fns/format';

const surveyList = [
    'survey44',
    'survey45',
    'survey46',
    'survey47',
    'survey48',
    'survey49',
    'survey50',
    'survey51',
    'survey52',
    'survey53',
    'survey54',
    'survey55',
    'survey41',
    'survey42',
    'survey43',
];

const assignmentHandles = {
    assignment14: "Sensitivity to Students' Culture",
    assignment15: 'Authentic Relationships',
    assignment16: 'Connection to the Curriculum',
    assignment17: 'Effective Communication',
    assignment18: 'Reflective Thinking',
};

export default settings => {
    return ({ row }) => {
        let completed = 0;
        let total = surveyList.length;

        const hasFlowProgress = !_isEmpty(row.flowProgressList);

        if (hasFlowProgress) {
            const completedSurveys = row.flowProgressList.filter(survey => survey.completed);

            surveyList.forEach(survey => {
                const match = completedSurveys.find(item => item.flowHandle === survey);
                if (match) {
                    completed++;
                }
            });
        }

        let icon = <IconExclamation />;
        let className = style.danger;
        if (completed === total) {
            icon = <IconCheck />;
            className = style.success;
        } else if (completed > 0) {
            icon = <IconHourGlass />;
            className = style.warning;
        }

        return (
            <Modal
                title={row['user.firstName'] + "'s Progress"}
                trigger={
                    <button type="button" className={style.progressLink}>
                        <span className={className}>
                            {icon}&nbsp;
                            <span className={style.underline}>
                                {completed} module{completed !== 1 ? 's' : ''} complete
                            </span>
                        </span>
                    </button>
                }
                size="large"
            >
                <Table
                    rowKey="surveyId"
                    name="courseProgress"
                    query={query}
                    graphqlVariables={{ pawnId: row['pawn.pawnId'] }}
                    columns={[
                        {
                            name: 'label',
                            label: 'Professional Development Module',
                            filter: true,
                            sort: true,
                            render: props => {
                                const title =
                                    assignmentHandles[props.row['assignment.assignmentHandle']];

                                return `${title} - ${props.value}`;
                            },
                        },
                        {
                            name: 'flowStarted',
                            label: 'Start Date',
                            sort: true,
                            filter: true,
                            filterSettings: { type: 'date' },
                            render: ({ value }) =>
                                value === 'Not yet started' ? (
                                    <span className="text-danger">{value}</span>
                                ) : (
                                    format(value, 'MM/DD/YYYY')
                                ),
                        },
                        {
                            name: 'flowCompleted',
                            label: 'Complete Date',
                            sort: true,
                            filter: true,
                            filterSettings: { type: 'date' },
                            render: ({ value }) =>
                                value === 'Not yet completed' ? (
                                    <span className="text-danger">{value}</span>
                                ) : (
                                    format(value, 'MM/DD/YYYY')
                                ),
                        },
                    ]}
                />
            </Modal>
        );
    };
};
