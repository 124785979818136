import React from 'react';
import CheckMark from 'cccisd-icons/checkmark-circle';

export default settings => {
    return ({ value, row, isCsv }) => {
        if (isCsv) {
            return value ? value : false;
        }

        return (
            <div key={row} className="text-center" style={{ fontSize: '16pt', color: '#449D44' }}>
                {value && <CheckMark />}
            </div>
        );
    };
};
