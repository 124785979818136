import React from 'react';

// "settings": {
//     "namePath": "descendantRoles.clientList",
//     "valuePath": "metricsList.devTags.climateActivities",
//     "prefix": "",
//     "suffix": "",
//     "decimalPlaces": 1
// }

export default settings => {
    return ({ value, row }) => {
        let stringArr;
        if (value) {
            stringArr = value.split(' ');
        }
        return <span>{stringArr && <span>{stringArr[0]}</span>}</span>;
    };
};
