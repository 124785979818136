import Component from './Component.js';
import thumbnail from './thumbnail.png';

export default {
    handle: 'app_linen',
    label: 'Blue Linen',
    component: Component,
    thumbnail,
    // flowPlayerSettings: {
    //     background: 'solidColor',
    //     backgroundColor: '#fff',
    // },
};
