import React from 'react';

export default settings => {
    return ({ value, row }) => {
        let assignment;
        if (value && value.length > 0) {
            assignment = value[0];
        }
        return (
            <div key={row} className="text-center">
                {assignment && <span>{assignment.completedDateFormated}</span>}
            </div>
        );
    };
};
