import React from 'react';
import style from './style.css';
import logo from './DoubleCheckLogoFooter.png';
import IESLogo from './IES-Institute_of_Education_Sciences.png';

const { wpUrl } = window.cccisd.appDefs.app;

export default class Component extends React.Component {
    getYear = () => {
        const date = new Date().getFullYear();
        return date;
    };

    render() {
        const isQuest =
            window.location.pathname &&
            window.location.pathname.includes('/quest') &&
            window.location.pathname.includes('/design/flow');

        return (
            <div className={style.footer} id="layout_footer">
                <div>
                    <div className={isQuest ? style.none : style.logoBox}>
                        <img className={style.logo} alt="NOT FOUND" src={logo} width="200px" height="110px" />
                        <div className={style.textBox}>
                            <img
                                alt="NOT FOUND"
                                src={IESLogo}
                                width="45%"
                                style={{ marginBottom: '10px', marginTop: '10px' }}
                            />

                            <div>
                                Funded by the{' '}
                                <strong>U.S. Department of Education, Institute of Education Sciences.</strong>{' '}
                            </div>
                            <div>R305A130375 awarded to Principal Investigator Dr. Wendy M. Reinke.</div>
                        </div>
                        <div className={style.links}>
                            <a
                                style={{
                                    borderBottom: '1px solid grey',
                                    paddingRight: '3em',
                                    color: '#fff',
                                }}
                                href="https://www.doublecheckcoaching.org"
                            >
                                Home
                            </a>
                            <a style={{ color: '#fff' }} href={`${wpUrl}/contact/`}>
                                {' '}
                                Contact
                            </a>
                        </div>
                    </div>
                    <div className={style.wrapper}>
                        <div>
                            <span>
                                Website and software developed by <a href="https://www.3cisd.com/">3C Institute</a>.
                                <span className={style.spaceLeft}>© 2019-{this.getYear()} </span>
                                <a href="https://missouri.edu/">University of Missouri</a>.
                            </span>{' '}
                            <span className={style.spaceLeft}>All rights reserved. </span>
                            <span className={style.spaceLeft}>
                                <a href="https://www.3cisd.com/terms-of-use">Terms of Use</a> |{' '}
                                <a href="https://www.3cisd.com/privacy-policy">Privacy Policy</a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
