import React from 'react';
import Tooltip from 'cccisd-tooltip';
import UnlinkIcon from 'cccisd-icons/unlink4';
import axios from 'cccisd-axios';
import Confirm from 'cccisd-confirm';
import Modal from 'cccisd-modal';
import { addEvent } from 'cccisd-laravel-eventlog';
import MessageUser from 'cccisd-icons/envelop2';
import { SecureMessagingSingleConversation } from 'cccisd-laravel-secure-messaging';

import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

export default settings => {
    return ({ value, row, loadData }) => {
        const userPawnId = Fortress.user.acting.id;
        const userRole = Fortress.user.acting.role.handle;
        const isCoach = userRole === 'coach';
        const modal = React.createRef();

        const sendPost = async () => {
            const parentPawns = row['parentRoles.anyRoleList'].map(item => item.pawn.pawnId);
            const filtered = parentPawns.filter(item => item !== userPawnId);

            const data = {
                username: row['user.email'],
                first_name: row['user.firstName'],
                last_name: row['user.lastName'],
                parentPawns: filtered.length > 0 ? JSON.stringify(filtered) : null,
                pawnId: row['pawn.pawnId'],
                pawnHash: row['pawn.pawnHash'],
            };

            const response = await axios.put(
                Boilerplate.route('api.nexus.pawn.update', { pawn: row['pawn.pawnId'] }),
                data
            );

            if (response.data && response.data.status === 'success') {
                const metadata = isCoach
                    ? {
                          coach: {
                              name: Fortress.user.acting.user.full_name,
                              pawnId: Fortress.user.acting.id,
                          },
                          teacher: {
                              name: row['user.firstName'] + ' ' + row['user.lastName'],
                              pawnId: row['pawn.pawnId'],
                          },
                      }
                    : {
                          admin: {
                              name: Fortress.user.acting.user.full_name,
                              pawnId: Fortress.user.acting.id,
                          },
                          teacher: {
                              name: row['user.firstName'] + ' ' + row['user.lastName'],
                              pawnId: row['pawn.pawnId'],
                          },
                      };
                addEvent(
                    {
                        eventType: isCoach ? 'unlinkCoach' : 'unlinkAdmin',
                        targetId: row['pawn.pawnId'],
                        targetType: 'pawn',
                        metadata: JSON.stringify(metadata),
                    },
                    { saveImmediately: true }
                );

                modal.current.open();
            }
        };

        const confirm = async () => {
            Confirm({
                message: `Confirm Unlink`,
                description: `If you unlink from this ${row['pawn.role']} you will no longer be able to view their Double Check data. Are you sure you want to unlink from this ${row['pawn.role']}?`,
                confirmLabel: 'Continue',
                abortLabel: 'Cancel',
            })
                .then(() => {
                    sendPost();
                })
                .fail(() => {
                    // close modal
                });
        };
        const closeModal = () => {
            modal.current.close();
        };
        // const label = isCoach ? 'My Teachers' : 'My Team';

        return (
            <div key={row} className="text-center">
                <Modal
                    trigger={
                        <Tooltip title="Message Teacher">
                            <button type="button" className={'btn btn-sm btn-primary ' + style.btnSpacing}>
                                <MessageUser />
                            </button>
                        </Tooltip>
                    }
                    title="Message Teacher"
                    render={() => (
                        <div className={style.messageScroll}>
                            <SecureMessagingSingleConversation
                                primaryParticipant={{
                                    // coach
                                    id: Fortress.user.acting.id,
                                    firstName: Fortress.user.acting.first_name,
                                    lastName: Fortress.user.acting.last_name,
                                    username: Fortress.user.acting.username,
                                }}
                                anotherParticipant={{
                                    // teacher
                                    id: row['pawn.pawnId'],
                                    firstName: row['user.firstName'],
                                    lastName: row['user.lastName'],
                                    username: row['user.username'],
                                }}
                            />
                        </div>
                    )}
                />
                <div className="btn btn-sm" onClick={confirm} style={{ backgroundColor: '#E89622', color: '#fff' }}>
                    <Tooltip title={isCoach ? 'Unlink Teacher' : 'Unlink Team Member'}>
                        <UnlinkIcon />
                    </Tooltip>
                </div>
                <Modal ref={modal} size="medium" title="Unlink Successful" beforeClose={loadData}>
                    <div className="alert alert-success">{`You have successfully unlinked from this ${
                        row['pawn.role']
                    }. If you would like to relink with this ${
                        row['pawn.role']
                    } later, you can invite them to connect with you on the ${
                        isCoach ? "'My Teachers'" : "'My Team'"
                    } page.`}</div>
                    <div style={{ textAlign: 'center' }}>
                        <button
                            type="button"
                            className="btn btn-default"
                            onClick={closeModal}
                            style={{ backgroundColor: '#458dcb', color: '#fff' }}
                        >
                            Close
                        </button>
                    </div>
                </Modal>
            </div>
        );
    };
};
